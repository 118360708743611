'use client';

import { useEffect } from 'react';
import { ComponentProps } from 'react';
import { Link } from '@/navigation.public';
import { useActiveLink } from '@/components/ActiveLinkContext';

export default function NavLink({ href, ...rest }: ComponentProps<typeof Link>) {
  const { activeLink, setActiveLink } = useActiveLink();

  useEffect(() => {
    const handleScroll = () => {
      if (typeof href !== 'string') {
        console.error(': Error -> href is not a string:', href);
        return;
      }

      const sanitizedHref = href.startsWith('/#') ? href.slice(2) : href.startsWith('#') ? href.slice(1) : href;
      try {
        const targetElement = document.getElementById(sanitizedHref);
        if (!targetElement) {
          return;
        }

        const rect = targetElement.getBoundingClientRect();
        const isNearCenter = rect.top >= 0 && rect.top <= window.innerHeight / 2;

        if (isNearCenter) {
          setActiveLink(href);
          history.replaceState(null, '', `#${sanitizedHref}`);
        }
      } catch (error) {
        console.error(`:: Error -> Invalid id "${sanitizedHref}":`, error);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [href, setActiveLink]);

  const isActive = activeLink === href;

  const getActiveColor = () => {
    switch (href) {
      case '/#hero':
        return 'text-blue-400';
      case '/#languages':
        return 'text-green-400';
      case '/#projects':
        return 'text-purple-400';
      case '/#tools':
        return 'text-yellow-400';
      case '/#about':
        return 'text-pink-400';
      default:
        return 'text-gray-200';
    }
  };

  return (
    <Link
      aria-current={isActive ? 'page' : undefined}
      className={`transition-all duration-300 font-mono text-xs sm:text-sm md:text-base lg:text-lg ${
        isActive
          ? `${getActiveColor()} font-semibold underline decoration-1 sm:decoration-2 underline-offset-2 sm:underline-offset-4`
          : 'text-gray-200 hover:opacity-80'
      } p-1 sm:p-2 rounded-md`}
      href={href}
      {...rest}
    />
  );
}