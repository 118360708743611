import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(public)/[locale]/LocaleSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(public)/[locale]/NavLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/ActiveLinkContext.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
