'use client';

import { useLocale } from 'next-intl';
import { Locale } from '@/config';
import { useRouter, usePathname } from '@/navigation.public';
import { GB, HU } from 'country-flag-icons/react/3x2';

export default function LocaleSwitcher() {
  const currentLocale = useLocale() as Locale;
  const router = useRouter();
  const pathname = usePathname();

  const handleLocaleChange = () => {
    const newLocale = currentLocale === 'en' ? 'hu' : 'en';
    router.replace(pathname, { locale: newLocale });
  };

  const nextLocale = currentLocale === 'en' ? 'hu' : 'en';
  const FlagComponent = nextLocale === 'en' ? GB : HU;

  return (
    <button
      onClick={handleLocaleChange}
      className="fixed top-0 left-0 z-50 p-4 flex items-center"
    >
      <FlagComponent className="w-6 h-4 mr-2" />
      <span>{nextLocale.toUpperCase()}</span>
    </button>
  );
}
